<template>
  <div class="parking-lot-dialog">
    <el-dialog
      :visible.sync="dataset.show"
      :before-close="dialogCloseHandler"
      width="860px"
      title="新增停车场"
      :destroy-on-close="true"
      :close-on-click-modal="false"
    >
      <el-form
        class="form-container"
        label-position="top"
        :model="form"
        ref="dialogForm"
        :rules="formRule"
      >
        <!-- 停车场基础信息 -->
        <div class="gray-card">
          <div class="card-title">停车场基础信息</div>
          <el-row :gutter="40">
            <el-col :span="12">
              <el-form-item label="停车场名称" prop="parkingName">
                <el-input
                  v-model="form.parkingName"
                  placeholder="请输入停车场名称"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="停车场编码" prop="parkLotCode">
                <el-input
                  v-model="form.parkLotCode"
                  placeholder="请输入停车场编码"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="接口地址" prop="appUrl">
                <el-input
                  v-model="form.appUrl"
                  placeholder="请输入接口地址"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="选择项目" prop="projectId">
                <el-select
                  style="width: 100%"
                  v-model="form.projectId"
                  placeholder="请选择项目"
                >
                  <el-option
                    v-for="item in projectList"
                    :value="item.projectId"
                    :label="item.projectName"
                    :key="item.projectId"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="车场类型" prop="parkingType">
                <el-radio v-model="form.parkingType" :label="1">园区</el-radio>
                <el-radio v-model="form.parkingType" :label="2">住宅</el-radio>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item
                label="支持线上续费月卡类型（RID）"
                prop="notPayMonthlyCardList"
              >
                <div
                  class="single-rid"
                  v-for="(item, index) in form.notPayMonthlyCardList"
                  :key="index"
                >
                  <el-input
                    class="rid-input"
                    v-model="item.rid"
                    placeholder="请输入RID"
                  ></el-input>
                  <el-button
                    v-show="form.notPayMonthlyCardList.length > 1"
                    type="text"
                    class="delele-button"
                    @click="deleteSingleRid(index)"
                    >删除</el-button
                  >
                </div>
              </el-form-item>
              <div class="add-line">
                <span class="add-inner" @click="addNewRid">
                  <i class="el-icon-plus"></i>
                  添加RID
                </span>
              </div>
            </el-col>
          </el-row>
        </div>
        <!-- 停车场云平台信息 -->
        <div class="gray-card">
          <div class="card-title">停车场云平台信息</div>
          <el-row :gutter="40">
            <el-col :span="12">
              <el-form-item label="接口地址" prop="cloudApiUrl">
                <el-input
                  v-model="form.cloudApiUrl"
                  placeholder="请输入接口地址"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="云平台ID" prop="cloudAppId">
                <el-input
                  v-model="form.cloudAppId"
                  placeholder="请输入云平台ID"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="云平台秘钥" prop="cloudAppSecret">
                <el-input
                  v-model="form.cloudAppSecret"
                  placeholder="请输入云平台秘钥"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="云平台KEY" prop="cloudPrivateKey">
                <el-input
                  v-model="form.cloudPrivateKey"
                  placeholder="请输入云平台KEY"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="停车场编号" prop="cloudParkingCode">
                <el-input
                  v-model="form.cloudParkingCode"
                  placeholder="请输入停车场编号"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
        <!-- 停车场开票信息 -->
        <div class="gray-card">
          <div class="card-title">停车场开票信息</div>
          <el-row :gutter="40">
            <el-col :span="12">
              <el-form-item label="停车场面积" prop="bdcmj">
                <el-input
                  v-model="form.bdcmj"
                  placeholder="请输入停车场面积"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="产权证书号" prop="cqzsh">
                <el-input
                  v-model="form.cqzsh"
                  placeholder="请输入产权证书号"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="省市区县" prop="bdcdz">
                <el-input
                  v-model="form.bdcdz"
                  placeholder="请输入省市区县"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="详细地址" prop="bdcjtdz">
                <el-input
                  v-model="form.bdcjtdz"
                  placeholder="请输入详细地址"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="跨地(市)标志" prop="kdsbz">
                <el-select
                  style="width: 100%"
                  v-model="form.kdsbz"
                  placeholder="请选择项目"
                  clearable
                >
                  <el-option value="1" label="是"></el-option>
                  <el-option value="0" label="否"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="单位(m²)" prop="dw">
                <el-input
                  v-model="form.dw"
                  placeholder="请输入单位(m²)"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
        <!-- 申请月卡类型 -->
        <div class="gray-card">
          <div class="card-title">申请月卡类型</div>
          <el-row :gutter="40">
            <el-col :span="24">
              <el-form-item label="系统组织ID" prop="orginazitionId">
                <el-input
                  v-model="form.orginazitionId"
                  placeholder="请输入系统组织ID"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="类型名称" prop="cardManageInfoList">
                <div
                  class="type-list"
                  v-for="(item, index) in form.cardManageInfoList"
                  :key="index"
                >
                  <el-input
                    class="type-input"
                    v-model="item.monthCardType"
                    placeholder="请输入月卡类型"
                  ></el-input>
                  <el-input
                    class="type-input"
                    v-model="item.rid"
                    placeholder="请输入RID"
                  ></el-input>
                  <el-input-number
                    class="type-input"
                    v-model="item.cardAmount"
                    placeholder="请输入月卡金额"
                    :controls="false"
                    :min="1"
                    :max="999999"
                  ></el-input-number>
                  <el-button
                    type="text"
                    class="delele-button"
                    @click="deleteSingleType(index)"
                    >删除</el-button
                  >
                </div>
              </el-form-item>
              <div class="add-line">
                <span class="add-inner" @click="addNewType">
                  <i class="el-icon-plus"></i>
                  添加类型
                </span>
              </div>
            </el-col>
          </el-row>
        </div>
      </el-form>
      <div class="button-line">
        <r-button type="cancel" @click="dialogCloseHandler">取消</r-button>
        <r-button plain @click="submitHandler" :loading="submitLoading"
          >保存</r-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { parkingLotAdd, parkingLotEdit } from "@/api/ruge/lego/config";
import { cloneDeep } from "lodash";

export default {
  name: "parking-lot-dialog",
  props: {
    dataset: {
      type: Object,
      default() {
        return {
          show: false,
        };
      },
    },
    projectList: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      submitLoading: false,
      form: {
        id: null,
        parkingName: null,
        parkLotCode: null,
        appUrl: null,
        projectId: null,
        parkingType: 1, //(1:园区，2:住宅)
        notPayMonthlyCardList: [{ rid: null }],
        cloudApiUrl: null,
        cloudAppId: null,
        cloudAppSecret: null,
        cloudPrivateKey: null,
        cloudParkingCode: null,
        bdcmj: null,
        cqzsh: null,
        bdcdz: null,
        bdcjtdz: null,
        kdsbz: null, //（1：是，0否）
        dw: null,
        orginazitionId: null,
        cardManageInfoList: [
          // {
          //   monthCardType: null,
          //   rid: null,
          //   cardAmount: undefined,
          // },
        ],
      },
      formRule: {
        parkingName: [
          {
            required: true,
            trigger: ["blur", "change"],
            message: this.$t("validate.required"),
          },
        ],
        parkLotCode: [
          {
            required: true,
            trigger: ["blur", "change"],
            message: this.$t("validate.required"),
          },
        ],
        appUrl: [
          {
            required: true,
            trigger: ["blur", "change"],
            message: this.$t("validate.required"),
          },
        ],
        projectId: [
          {
            required: true,
            trigger: ["blur", "change"],
            message: this.$t("validate.required"),
          },
        ],
        parkingType: [
          {
            required: true,
            trigger: ["blur", "change"],
            message: this.$t("validate.required"),
          },
        ],
        // notPayMonthlyCardList: [
        //   {
        //     required: true,
        //     trigger: ["blur", "change"],
        //     message: this.$t("validate.required"),
        //   },
        //   {
        //     validator: (rule, value, callback) => {
        //       for (let item of this.form.notPayMonthlyCardList) {
        //         const { rid } = item;
        //         if (!rid) {
        //           callback(new Error("请填写RID"));
        //           break;
        //         }
        //       }
        //       callback();
        //     },
        //     trigger: ["blur", "change"],
        //   },
        // ],
      },
    };
  },
  created() {
    this.initDatas();
  },
  methods: {
    initDatas() {
      if (this.dataset.datas && this.dataset.datas.id) {
        let temp = cloneDeep(this.dataset.datas);
        const cloneObj = cloneDeep(this.dataset.datas.cardManageInfoList);
        temp.orginazitionId = cloneObj.length
          ? cloneObj[0].orginazitionId
          : null;
        this.form = temp;
      }
    },
    submitHandler() {
      this.$refs.dialogForm.validate(async (valid, object) => {
        if (valid) {
          this.submitLoading = true;
          const params = { ...this.form };
          for (let i = params.cardManageInfoList.length - 1; i >= 0; i--) {
            const { cardAmount, monthCardType, rid } =
              params.cardManageInfoList[i];
            if (!cardAmount && !monthCardType && !rid) {
              params.cardManageInfoList.splice(i, 1);
            }
          }
          params.cardManageInfoList.forEach((item) => {
            item.orginazitionId = params.orginazitionId;
          });
          console.log("params", params);
          let result = null;
          if (this.dataset.datas.id) {
            result = await parkingLotEdit(params);
          } else {
            result = await parkingLotAdd(params);
          }
          const { code, msg } = result;
          if (code === 200) {
            this.$message.success("保存成功！");
            this.$emit("close", true);
          } else if (code === 500) {
            this.$message.warning(msg);
          }
          this.submitLoading = false;
        } else {
          try {
            this.$nextTick(() => {
              const errorDom =
                this.$refs.dialogForm.$el.querySelectorAll(".is-error")[0];
              errorDom.scrollIntoView({
                block: "center",
                behavior: "smooth",
              });
            });
          } catch (error) {}
        }
      });
    },
    dialogCloseHandler(flag) {
      this.$emit("close", flag);
    },
    deleteSingleRid(index) {
      this.form.notPayMonthlyCardList.splice(index, 1);
    },
    addNewRid() {
      this.form.notPayMonthlyCardList.push({
        rid: null,
      });
    },
    deleteSingleType(index) {
      this.form.cardManageInfoList.splice(index, 1);
    },
    addNewType() {
      this.form.cardManageInfoList.push({
        monthCardType: null,
        rid: null,
        cardAmount: undefined,
      });
    },
  },
};
</script>

<style lang="less" scoped>
.parking-lot-dialog {
  .form-container {
    max-height: calc(100vh - 260px);
    overflow: hidden auto;
  }
  .gray-card {
    background: #f5f7f9;
    border-radius: 10px;
    padding: 20px 10px;
    .card-title {
      margin-bottom: 15px;
      font-weight: 600;
      font-size: 14px;
      color: #252d3d;
    }
  }
  .gray-card + .gray-card {
    margin-top: 20px;
  }
  .single-rid {
    display: flex;
    justify-content: space-between;
    .rid-input {
      flex: 1;
    }
  }
  .delele-button {
    color: #ff0000;
    display: inline-block;
    margin-left: 15px;
  }
  .single-rid + .single-rid {
    margin-top: 15px;
  }
  .type-list {
    display: flex;
    justify-content: space-evenly;
    .type-input {
      flex: 1;
      ::v-deep.el-input-number.is-without-controls .el-input__inner {
        text-align: left;
      }
    }
    .type-input + .type-input {
      margin-left: 15px;
    }
  }
  .type-list + .type-list {
    margin-top: 20px;
  }
  .add-line {
    text-align: center;
    margin-top: 20px;
    .add-inner {
      color: #2a61ff;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      .el-icon-plus {
        font-weight: bold;
        font-size: 16px;
        margin-right: 2px;
      }
    }
  }
  .button-line {
    text-align: right;
    margin: 15px 0 5px;
  }
}
</style>