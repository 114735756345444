<template>
  <div class="app-container parking-lot-config">
    <div class="button-line">
      <r-button plain @click="addNewConfig">新增停车场</r-button>
      <r-button @click="initDatas">刷新</r-button>
    </div>
    <div class="table-container">
      <FinalTable
        ref="finalTableRef"
        :datas="dataset"
        @tableEventHandler="tableEventHandler"
      />
    </div>
    <NewParkingLotDialog
      v-if="newConfigDialog.show"
      :projectList="projectList"
      :dataset="newConfigDialog"
      @close="dialogCloseHandler"
    />
  </div>
</template>

<script>
import FinalTable from "@/components/FinalTable";
import { getProjectList } from "@/api/business/base/tenant/map";
import NewParkingLotDialog from "./components/newParkingLotDialog.vue";
import { getParkingLotList, parkingLotDelete } from "@/api/ruge/lego/config";
export default {
  name: "parking-lot-config",
  components: {
    FinalTable,
    NewParkingLotDialog,
  },
  data() {
    return {
      loadingFlag: false,
      projectList: [],
      newConfigDialog: {
        show: false,
        datas: {},
      },
      dataset: {
        paginationConfig: {
          need: true,
        },
        filterLine: {
          show: true,
        },
        header: [
          { prop: "parkingName", label: "停车场名称", width: "" },
          { prop: "createBy", label: "创建人", width: "" },
          { prop: "createTime", label: "创建时间", width: "" },
          { prop: "updateBy", label: "修改人", width: "" },
          { prop: "updateTime", label: "修改时间", width: "" },
          { prop: "operation", label: "操作", width: "120" },
        ],
        tableData: [],
        // 搜索行配置
        searchLineConfig: {
          parkingName: {
            type: "input",
            label: "停车场名称",
            value: "",
            actionType: "goSearch",
            placeholder: "请选择停车场名称",
            prefixIcon: "el-icon-search",
          },
        },
        // 表格内容配置
        detailConfig: {
          createTime: {
            type: "dateFormat",
          },
          updateTime: {
            type: "dateFormat",
          },
          operation: {
            type: "icon",
            iconList: [
              {
                actionType: "iconClick",
                eventName: "edit",
                fontSize: "14px",
                color: "#1A4CEC",
                cursorPointer: "pointer",
                iconName: "el-icon-document-copy",
                tooltips: "编辑",
              },
              {
                actionType: "iconClick",
                eventName: "delete",
                fontSize: "14px",
                color: "#E47470",
                cursorPointer: "pointer",
                iconName: "el-icon-delete",
                tooltips: "删除",
              },
            ],
          },
        },
        // 高级搜索配置
        advanceFilterConfig: {},
        pageVO: {
          current: 1,
          rowCount: 10,
          total: 0,
        },
      },
      filterObj: {
        current: 1,
        rowCount: 10,
        parkingName: "",
      },
    };
  },
  watch: {
    loadingFlag: {
      handler(val) {
        this.$refs.finalTableRef.loadingToggle(val);
      },
    },
  },
  created() {
    this.initDatas();
    this.initProject();
  },
  methods: {
    dialogCloseHandler(flag) {
      this.newConfigDialog.show = false;
      flag === true && this.initDatas();
    },
    addNewConfig() {
      this.newConfigDialog.show = true;
      this.newConfigDialog.datas = {};
    },
    initProject() {
      getProjectList().then((res) => {
        this.projectList = res || [];
        this.dataset.searchLineConfig.projectId.optionList = res.map((item) => {
          return {
            value: item.projectId,
            label: item.projectName,
          };
        });
      });
    },
    initDatas() {
      this.loadingFlag = true;
      getParkingLotList(this.filterObj)
        .then((res) => {
          this.dataset.tableData = res.rows;
          this.dataset.pageVO.total = res.total;
        })
        .finally(() => {
          this.loadingFlag = false;
        });
    },
    tableEventHandler(datas) {
      if (datas.type === "goSearch") {
        this.filterObj.current = 1;
        this.filterObj = { ...this.filterObj, ...datas.params };
        this.initDatas();
      } else if (datas.type === "paginationChange") {
        this.filterObj.current = datas.params.current.page;
        this.filterObj.rowCount = datas.params.current.limit;
        this.initDatas();
      } else if (datas.type === "iconClick") {
        switch (datas.eventName) {
          case "delete":
            this.deleteConfig(datas.row);
            break;
          case "edit":
            this.editConfig(datas.row);
            break;
        }
      }
    },
    editConfig(rowDatas) {
      this.newConfigDialog.datas = rowDatas;
      this.newConfigDialog.show = true;
    },
    async deleteConfig({ id }) {
      await this.$confirm(`确认删除该配置？`, this.$t("commons.warning"), {
        confirmButtonText: this.$t("commons.confirm"),
        cancelButtonText: this.$t("commons.cancel"),
        type: "warning",
      });
      parkingLotDelete({ id }).then(() => {
        this.$message.success("删除成功！");
        this.initDatas();
      });
    },
  },
};
</script>

<style lang="less" scoped>
.parking-lot-config {
  .button-line {
    margin-bottom: 10px;
  }
}
</style>